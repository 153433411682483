import Leaflet from "views/Leaflet";
import Categories from "views/Categories";
import Pages from "views/Pages";
import { serverResponse } from '../util/fakeServer';

const dashboardRoutes = [
  { path: "/products/:item_id?", component: Leaflet},
  { path: "/leaflet/:index?", component: Leaflet},
  { path: "/categories/:name?", component: Categories},
  { path: "/pages", component: Pages}
];
 
// serverResponse.leaflet.pages.forEach(page => {
//   page.products.forEach(product => {
//     dashboardRoutes.push({ path: "/products/" + product.item_id, component: Leaflet});
//   });
// });



export default dashboardRoutes;