import React, { useState, useEffect } from "react";
import HeaderWrapper from "./header.style";
import { iconDemo, iconAppName } from "helper/constant";

/*
import englishLang from "assets/images/uk.svg";
import chineseLang from "assets/images/china.svg";
import spanishLang from "assets/images/spain.svg";
import frenchLang from "assets/images/france.svg";
import italianLang from "assets/images/italy.svg";
import IntlMessages from "util/intlMessages";
import { Scrollbars } from "react-custom-scrollbars";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import {
    friend1,
    friend2,
    friend3,
    friend4,
    ProfileLockScreen
} from "helper/constant";
*/
import {
    InputGroup,
    InputGroupAddon,
    Input
} from 'reactstrap';
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import { AppName } from "helper/constant";
import GroceryList from "../floatingGroceryList/GroceryList";
import SearchBar from "../searchBar/SearchBar";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import NewListMobile from '../../assets/images/icons/new_list_mobile.png';
import { serverResponse } from '../../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../settings/gaConfig';

const { logout } = AuthActions;

const Header = props => {

    useEffect(() => {
        ReactGA.initialize(serverResponse.config.ga_tracking_id);
    });

    /*
    const [notificationPopoverOpen, setNotificationPopoverOpen] = useState(
        false
    );
    const [languagePopover, setLanguagePopover] = useState(false);
    const [profilePopover, setProfilePopover] = useState(false);
    */

    const { drawerMiniMethod, mini } = props;

    let [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => {
        setPopoverOpen(!popoverOpen);
        if (popoverOpen) {
            ReactGA.pageview("/" + serverResponse.config.release_id + "/lista-spesa");
        }
    }

    let [searchBarOpen, setSearchBarOpen] = useState(false);

    const searchBarToggle = () => {
        setSearchBarOpen(!searchBarOpen);
    }

    /*let [searchText, setSearchText] = useState("");

    const handleClickSearch = () => {
        props.history.push("/categories/" + searchText);
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    }*/

    return (
        <HeaderWrapper {...props}>
            <div className="headerBack" style={{ marginRight: "80px" }}>
                <ul className="list-inline ma-0 custom-search-box">
                    <li className="list-inline-item feed-text-area-icon">
                        <div className="drawer-handle-arrow">
                            {mini ? (
                                <button
                                    className="top-header-icon c-btn"
                                    onClick={drawerMiniMethod()}
                                >
                                    <i className="fas fa-arrow-right" />
                                </button>
                            ) : (
                                    <button
                                        className="top-header-icon c-btn"
                                        onClick={drawerMiniMethod()}
                                    >
                                        <i className="fas fa-arrow-left" />
                                    </button>
                                )}
                        </div>
                        <div
                            className="mini-drawer-menu-icon"
                            onClick={drawerMiniMethod()}
                        >
                            <i className="fas fa-bars" />{" "}

                            <span className="app-name">{AppName}</span>
                        </div>
                    </li>

                    {/* grocery icon */}
                    <li className="list-inline-item feed-text-area-icon pull-right mr-8 mobile-grocery-list">
                        <button
                            className="top-header-icon c-btn"
                            id="mobile-grocery"
                            onClick={toggle}
                        >

                                <img id="" src={NewListMobile} style={{ cursor: "pointer", width: "32px", height: "32px", position: "relative", top: '-7px' }} alt="list" />
                                <NotificationBadge count={props.groceryList.length} effect={Effect.SCALE} className="custom-mobile-grocery-notification-badge" />

                        </button>
                    </li>

                    {/* search icon */}
                    <li className="list-inline-item feed-text-area-icon pull-right mr-8 mobile-grocery-list">
                        <button
                            className="top-header-icon c-btn"
                            id="mobile-search"
                            onClick={searchBarToggle}
                        >
                            <i className="fas fa-search" />
                        </button>
                    </li>

                    {/* search bar */}
                    {

                        props.location.pathname !== '/categories'

                        &&

                        <li className="list-inline-item custom-search-li">

                            <InputGroup>
                                <Input
                                    type="text"
                                    name="serach"
                                    id="search"
                                    placeholder="Cerca un prodotto"
                                    value={props.searchText}
                                    onChange={props.handleSearchTextChange}
                                    onKeyUp={(e) => {
                                        props.handleSearch2(props.searchText);
                                        /*if (e.keyCode === 13) {
                                            handleClickSearch()
                                        } else {
                                            setSearchText(e.target.value)
                                        }*/
                                    }}
                                />
                                <InputGroupAddon addonType="append"><span className="input-group-text" ><FontAwesomeIcon icon={faSearch} /></span></InputGroupAddon>
                            </InputGroup>

                        </li>
                    }



                </ul>

                <GroceryList
                    isOpen={popoverOpen}
                    toggle={toggle}
                    placement={"bottom"}
                    target="mobile-grocery"
                    isMobile={true} />

                <SearchBar
                    isOpen={searchBarOpen}
                    toggle={searchBarToggle}
                    placement={"bottom"}
                    target="mobile-search"
                    isMobile={true} />

            </div>
        </HeaderWrapper>
    );
};

const mapStateToProps = state => {
    return {
        groceryList: state.groceryList.list,
    };
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        { logout }
    )
)(Header);
